.vitamin-levels-tier-element {
    font-style: normal;
    font-size: 18px;
    line-height: 30px;;
    text-align: center;
    color: #53227E;
    max-width: 300px;
    width: 100%;
}

.vitamin-levels-tier-element__image {
    max-height: 120px;
    margin-bottom: 15px;
}

.vitamin-levels-tier-element__header {
    font-weight: bold;
}