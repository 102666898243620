.header-bottom {
    height: 79px;
    width: 100%;
    background: linear-gradient(90deg, #F0970C 35%, rgba(226, 155, 57, 0) 60%);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 6;
}

.header-bottom__logo {
    max-height: 102px;
    margin-top: -40px;
    left: 10%;
    z-index: 2;
    position: absolute;
}

.header-bottom__logo-background {
    position: absolute;
    left: 0;
    top: -79px;
    max-height: 226px;
    z-index: 1;
    max-width: 100%;
}

.header-bottom__text {
    color: #F17728;
    margin-right: 200px;
    font-size: 30pt;
    font-weight: bold;
    letter-spacing: 0.01px;
}

.header-bottom__sun-logo-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
}

.header-bottom__sun-logo {
    max-width: 285px;
    max-height: 257px;
    margin-right: -2px;
    margin-top: -23px;
}

@media screen and (max-width: 1090px) {
    .header-bottom__text {
        display: none;
    }

    .header-bottom__sun-logo {
        max-height: 175px;
        margin-top: -30px;
    }

    .header-bottom__logo-background {
        left: calc(0px - (1280px - 100vw) * 0.1);
    }
}

@media screen and (max-width: 500px) {
    .header-bottom__logo {
        width: 50%;
    }
}
