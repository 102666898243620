.vitamin-levels-tier-one-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
}

.vitamin-levels-tier-one__text {
    justify-content: center;
    max-width: 200px;
    font-style: normal;
    font-size: 18px;
    line-height: 30px;;
    color: #53227E;
    padding: 10px;
    text-align: center;
}

.vitamin-levels-tier-one__text span {
    font-weight: bold;
    display: block;
}

.vitamin-levels-tier-one-mobile {
    display: none;
}

@media screen and (max-width: 600px) {
    .vitamin-levels-tier-one-mobile {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .vitamin-levels-tier-one-desktop {
        display: none;
    }

    .vitamin-levels-tier-one__lower {
        display: flex;
    }

    .vitamin-levels-tier-one__text {
        width: 49%;
    }
}
