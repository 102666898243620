.vitamin-levels-tier-three {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.vitamin-levels-tier-three-mobile {
    display: none;
}

@media screen and (max-width: 600px) {
    .vitamin-levels-tier-three-mobile {
        display: unset;
    }

    .vitamin-levels-tier-three-desktop {
        display: none;
    }

    .vitamin-levels-tier-three-lower {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
}