.footer-header__upper {
    padding-top: 53px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    color: #000000;
}

.footer-header__lower {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 41px;
    text-align: center;
    color: #000000;
    padding-bottom: 10px;
}
