.about-vitamin-header-text {
    text-align: center;
    padding: 55px 20px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #551E7B;
}
