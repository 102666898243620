.vitamin-deficiency-item {
    max-width: 300px;
    width: 100%;
    padding: 10px;
    text-align: center;
    font-style: normal;
    font-size: 16px;
    line-height: 30px;;
    color: #53227E;
}

.vitamin-deficiency-item--large {
    max-width: 358px;
}

.vitamin-deficiency-item__header {
    margin-top: -20px;
    font-weight: bold;
    text-transform: uppercase;
}

.vitamin-deficiency-item__img {
    max-width: 100%;
}